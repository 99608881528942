import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { FirebaseContext } from "./Firebase";
import ImageUploading from "react-images-uploading";
import { v4 as uuidv4 } from "uuid";

export const ImageUpload = ({ onImageAdded = () => { }, handleChange, isOwner, moodBoard }) => {
  const fb = useContext(FirebaseContext);
  const [clipBoardInfo, setClipBoardInfo] = useState(null);
  const { uploadUserInputImages } = fb;
  const { t } = useTranslation();
  const id = uuidv4();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (isOwner && moodBoard?.imageUpload) {
      setImage(moodBoard.imageUpload);
      onImageAdded(moodBoard.imageUpload);
    }
  }, [isOwner, moodBoard]);

  const onChange = async (imageList, addUpdateIndex) => {
    setImage(null);
    setClipBoardInfo(t('image-upload.success'));

    const image = await uploadUserInputImages(id, imageList[0].file);
    setImage(image);
    onImageAdded(image);
    setClipBoardInfo(null);
  };

  return (
    <>
      {clipBoardInfo && (
        <p
          className={`border mt-3 p-3 text-base flex items-center justify-center rounded-lg ${clipBoardInfo.startsWith("Ooops")
              ? "border-red-900 text-red-900"
              : ""}`}
        >
          {clipBoardInfo}
        </p>
      )}
      <div className="flex gap-6 my-5">
        {image && (
          <span
            className={`relative cursor-pointer flex items-center rounded-xl ring-4 ring-pink-600`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute w-10 h-10 bottom-3 right-3 text-pink-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                fill="#ffffff" />
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd" />
            </svg>
            <img src={image} className="max-h-72 max-w-72 rounded-xl" />
          </span>
        )}
        <ImageUploading onChange={(imageList) => handleChange(onChange, imageList)} maxNumber={1}>
          {({
            onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps,
          }) => (
            <div
              onClick={onImageUpload}
              className={`flex items-center justify-center bg-pink-100 border-2 text-pink-800 rounded-xl focus:outline-none hover:opacity-70 ${isDragging
                  ? "animate-pulse border-solid border-pink-800"
                  : "border-dashed border-pink-300"}`}
            >
              <a
                className="text-lg text-center lg:mx-0 px-4 py-3  focus:outline-none"
                {...dragProps}
              >
                {t("image-upload.upload")}
              </a>
            </div>
          )}
        </ImageUploading>
      </div>
    </>
  );
};
