import React from "react"
import LoginOrProfile from "./LoginOrProfile"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Logo from "./BoardLogo"
import { Disclosure, Menu, Transition } from "@headlessui/react"

const myRoomDesignerDomains = ["myroomdesigner.ai", "www.myroomdesigner.ai"]

function checkIsMyRoomDesignerAI(path) {
  if (typeof window !== "undefined") {
    return (
      myRoomDesignerDomains.includes(window.location.hostname) ||
      path.startsWith("/interior-design-ai")
    )
  }
  return path.startsWith("/interior-design-ai")
}

const NavLink = React.forwardRef((props, ref) => (
  <a
    {...props}
    ref={ref}
    className={`block px-5 py-3 text-sm text-gray-900 transition-all duration-200 ease-in dark:text-gray-200 hover:translate-x-2 ${
      props.current ? 'translate-x-2 font-bold' : ''
    }`}
  >
    {props.children}
  </a>
))

const Button = React.forwardRef((props, ref) => (
  <button
    {...props}
    ref={ref}
    className="inline-flex items-center justify-center p-2 rounded-full text-pink-800 hover:bg-pink-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-200"
  >
    {props.children}
  </button>
))

export default function NavBar() {
  const router = useRouter()
  const { t } = useTranslation()

  const isMyRoomDesignerAI = checkIsMyRoomDesignerAI(router.asPath)

  const navigation = [
    {
      name: t("hero.button"),
      href: "/new-board",
      highlight: true,
      mobileOnly: true,
      showOnMyRoomDesignerAI: false,
    },
    {
      name: t("nav.ai"),
      href: "https://moodboardai.com/ai-generator",
      onClick: e => {
        e.preventDefault()
        router.push("/ai-generator")
      },
      showOnMyRoomDesignerAI: true,
    },
    {
      name: "Mood Board Editor",
      href: "https://moodboardai.com/#moodboard-editor",
      showOnMyRoomDesignerAI: true,
      onClick: e => {
        e.preventDefault()
        router.push("/#moodboard-editor")
      },
    },
    {
      name: "MyRoomDesigner.AI",
      // href: "https://myroomdesigner.ai",
      href: "#",
      onClick: e => {
        e.preventDefault()
        router.push("/interior-design-ai")
      },
      showOnMyRoomDesignerAI: true,
    },
  ]

  return (
    <Disclosure
      as="nav"
      className="sticky top-0 z-20 animated-gradient-bg bg-gradient-to-r from-red-100 via-blue-50 to-gray-50"
    >
      {({ open }) => (
        <div>
          <div className="mx-auto md:px-8 px-4 md:py-5 py-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-12 mr-4">
                  <a href="/">
                    <Logo className="h-12 w-12" />
                  </a>
                </div>
                <div className="hidden lg:block relative">
                  <Menu as="div" className="relative inline-block text-left outline-none ring-0 appearance-none">
                    {({ open }) => (
                      <>
                        <Menu.Button className="text-gray-900 hover:text-gray-700 outline-none ring-0 font-medium text-sm px-3 py-2 inline-flex items-center focus:outline-none focus:ring-0 appearance-none">
                          <span className="font-heading mr-2 text-sm">
                            {isMyRoomDesignerAI ? "MyRoomDesigner.AI" : "Mood Board AI"}
                          </span>
                          <svg
                            className={`h-4 w-4 transform transition-transform duration-200 ${
                              open ? 'rotate-180' : ''
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Menu.Button>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute font-heading left-0 mt-3 w-56 bg-white/60 backdrop-blur-md divide-y divide-gray-100 rounded-xl shadow-lg dark:bg-gray-700/60">
                            <div className="py-3 px-3">
                              {navigation
                                .filter(item => !item.mobileOnly)
                                .filter(
                                  item =>
                                    !isMyRoomDesignerAI || item.showOnMyRoomDesignerAI,
                                )
                                .map(({ name, href, current, onClick }) => (
                                  <Menu.Item key={name}>
                                    {({ active }) => (
                                      <NavLink
                                        href={href}
                                        current={router.asPath === href}
                                        onClick={onClick}
                                        className={`block px-6 py-4 text-sm text-gray-900 dark:text-gray-200 transition-all duration-200 rounded-md ${
                                          active ? 'translate-x-2' : ''
                                        }`}
                                      >
                                        {name}
                                      </NavLink>
                                    )}
                                  </Menu.Item>
                                ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
              <div className="flex items-center gap-4">
                {!isMyRoomDesignerAI && (
                  <div className="hidden lg:block">
                    <a
                      href="/new-board"
                      className="group relative font-heading block py-2 px-5 text-base rounded-full cursor-pointer text-white font-heading bg-gradient-to-r from-pink-900 to-pink-700 hover:ring-2 hover:ring-pink-300"
                    >
                      <p className="relative z-10">{t("hero.button")}</p>
                    </a>
                  </div>
                )}
                <LoginOrProfile />
                <div className="lg:hidden">
                  <Disclosure.Button as={Button}>
                    <svg
                      className="text-gray-800"
                      width={51}
                      height={51}
                      viewBox="0 0 56 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width={56}
                        height={56}
                        rx={28}
                        fill="currentColor"
                      />
                      {open ? (
                        <path
                          d="M19 19L37 37M37 19L19 37"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      ) : (
                        <path
                          d="M37 32H19M37 24H19"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      )}
                    </svg>
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation
                .filter(
                  item => !isMyRoomDesignerAI || item.showOnMyRoomDesignerAI,
                )
                .map(({ name, href, highlight, onClick }) => (
                  <Disclosure.Button
                    key={name}
                    as="a"
                    href={href}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${
                      highlight
                        ? "text-white bg-pink-900"
                        : "text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    }`}
                    aria-current={router.asPath === href ? "page" : undefined}
                    onClick={onClick}
                  >
                    {name}
                  </Disclosure.Button>
                ))}
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
